.about-page {
	background-color: #0d0d0d;
	width: 100vw;
	min-height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	.about-header {
		height: 122px;
		margin-top: 20px;
		@media screen and (max-width: 1100px) {
			margin-top: 0;
		}
		.about-box {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 0 8%;
			height: 122px;
			@media screen and (max-width: 420px) {
				height: 70px;
				justify-content: flex-start;
				padding: 0;
			}
			.header-box_logo {
				@media screen and (max-width: 420px) {
					position: absolute;
					margin-top: 8px;
					margin-left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}
	.about-section {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	.slider-review {
		margin-top: 30px;
		@media screen and (max-width: 420px) {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
	.footer {
		// margin-top: 68px;

		@media screen and (max-width: 420px) {
			// margin-top: 150px;
			// height: auto;
		}
	}
}
