.button-sign {
	@media screen and (max-width: 420px) {
		margin-left: auto;
		margin-right: 5px;
	}
	.btn-sign {
		width: 132px;
		height: 48px;
		background: linear-gradient(#0d0d0d, #0d0d0d) padding-box,
			linear-gradient(224.32deg, #3864ff 0%, #ff3f5b 95.47%) border-box;
		color: #f5f5f5;
		border: 2px solid transparent;
		text-transform: none;
		//font-family: Circular Std;
		font-family: "Circular Medium", Verdana;
		font-style: normal;
		font-weight: 500;
		font-size: 22px;
		line-height: 32px;
		@media screen and (max-width: 1100px) {
			font-size: 15px;
			line-height: 18px;
		}
		@media screen and (max-width: 420px) {
			width: 80px;
			height: 40px;
		}
	}
	.btn-sign:hover {
		background: linear-gradient(224.32deg, #3864ff 0%, #ff3f5b 95.47%) border-box;
	}
}
