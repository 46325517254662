.about-card {
  width: 100%;
  padding-left: 8%;
  padding-right: 8%;
  display: flex;
  // max-height: 881px;
  @media screen and (max-width: 1279px) {
    margin-left: 0;
  }
  @media screen and (max-width: 420px) {
    width: 300px;
    flex-direction: column;
    align-items: center;
  }
  .ac-block {
    margin-top: 96px;
    width: 50%;
    @media screen and (max-width: 420px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 300px;
      margin-top: 20px;
    }

    .ac-title {
      //font-family: Circular Std;
      font-family: "Circular Medium", Verdana;
      font-style: normal;
      font-weight: 500;
      font-size: 80px;
      line-height: 100px;
      color: #f5f5f5;
      margin-bottom: 60px;
      @media screen and (max-width: 420px) {
        font-size: 30px;
        line-height: 45px;
      }
    }
    .ac-context {
      font-family: Helvetica;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 150%;
      color: #f5f5f5;
      margin-bottom: 30px;
      p {
        text-indent: 10px;
        margin: 10px 0;
      }
      ol > li {
        margin-top: 20px;
      }
      ul {
        list-style-type: "- ";
        margin: 5px 0 5px -30px;
      }
    }

    .r-title {
      font-family: "Circular Medium", Verdana;
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 60px;
      color: #f5f5f5;
      @media screen and (max-width: 420px) {
        font-size: 30px;
      }
    }
    .identity-confirmation {
      margin-top: 56px;
    }
    .ac-picture {
      width: 100%;
      max-height: 762px;
      @media screen and (max-width: 420px) {
        display: none;
      }
    }
  }
  .move {
    margin-left: 40px;
    @media screen and (max-width: 420px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
