.footer {
	background-color: #0d0d0d;
	padding: 0 8%;
	height: 269px;
	margin-top: 50px; //29vh;;
	@media screen and (max-width: 1100px) {
		//margin-top: 150px;
	}
	@media screen and (max-width: 420px) {
		//margin-top: 20px;
		height: auto;
	}
	.footer-link {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.footer-link_last {
			margin-left: 72px;
			@media screen and (max-width: 420px) {
				margin-left: 0;
			}
		}
	}
	.footer-info {
		margin-top: 40px;
		@media screen and (max-width: 420px) {
			margin-top: 20px;
		}
		.footer-info_text {
			height: 52px;
			left: 160px;
			top: 189px;
			font-family: Helvetica;
			font-style: normal;
			font-weight: normal;
			font-size: 17px;
			line-height: 26px;
			color: #8e8e93;
			@media screen and (max-width: 420px) {
				height: auto;
				left: auto;
				top: auto;
			}
		}
	}
}
