.testimonials-block {
	position: relative;
	width: 500px;
	min-height: 200px;
	height: 100%;
	max-height: 350px;
	background: #1c1c1e;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	
	
	@media screen and(max-width: 1800px) {
		width: 400px;
	}
	@media screen and(max-width: 1400px) {
		width: 350px;
	}
	@media screen and (max-width: 1279px) {
		width: 300px;
	}
	@media screen and(max-width: 1100px) {
		width: 270px;
	}
	@media screen and(max-width: 850px) {
		width: 200px;
	}
	@media screen and(max-width: 420px) {
		min-height: 450px;
		height: 450px;
	}

	.tm-photo {
		
		position: absolute;
		left: 50%;
		transform: translate(-50px, -50px);
		@media screen and(max-width: 800px) {
			transform: translate(-50%, -50px);
		}
	}
	.tm-name {
		margin-top: 96px;
		font-family: Helvetica;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 32px;
		display: flex;
		align-items: center;
		text-align: center;
		color: #f5f5f5;
		text-overflow: ellipsis;
	}
	.tm-review {
		// padding-top: 15px;
		margin-top: 16px;
		margin-bottom: 40px;
		width: 84%;
		font-family: Helvetica;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		line-height: 26px;
		color: #f5f5f5;
		overflow:auto;
		
	}
	.tm-review::-webkit-scrollbar {
		width: 25px;
		
	}
	.tm-review::-webkit-scrollbar-track {
		background-color: none;
	}
}

