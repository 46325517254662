.emergency-popup {
  .MuiDialog-paperScrollPaper {
    max-height: 100%;
  }
  div .MuiPaper-root {
    background-color: rgba(28, 28, 30, 0.92);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(8px);
    color: #ffffff;
  }

  .signUp-dialog_statement {
    .context {
      display: flex;
      justify-content: center;

      align-items: center;
      margin-top: 16px;
      font-family: Helvetica;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 150%;
      // text-indent: 24px;
      color: rgba(255, 255, 255, 0.9);
      @media screen and(max-width: 1600px) {
        margin-top: 10px;
        font-size: 18px;
        line-height: 27px;
      }
    }
    .subcontext {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      font-family: Helvetica;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      // text-indent: 24px;
      color: rgba(255, 255, 255, 0.9);
      @media screen and(max-width: 1600px) {
        margin-top: 10px;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .dialog-btn {
    display: flex;
    justify-content: center;
    .btn {
      text-transform: capitalize;
      color: #ffa635;
    }
  }
  .btn-black {
    width: 115px;
    height: 50px;
    background: #2c2c2e;
    border-radius: 6px;
    text-transform: none;
    margin-left: 20px;
    margin-right: 10px;
    font-family: "Circular Medium", Verdana;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: white;
    &:hover {
      background: #2c2c2e;
    }
  }
}
